html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: Oswald, sans-serif;
  min-height: 100%
}

.aboutEntity {
  font-weight: bold !important;
  margin-top: 50px !important;
  margin-bottom: 5px;
}


.main {
  /* display: grid; */
}

.gridContainer {
  /* display: grid; */
}

.billboardContainer {
  position: relative;
  top: 0;
  left: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  height: calc(100vh - 80px);
  /* Set the container height to 100% of the viewport height */
  margin: 0;
}

.billboardContainer img {
  width: auto;
  /* Allow the image to be its original size */
  height: auto;
  /* Allow the image to be its original size */
  max-width: 100%;
  /* Ensure the image does not exceed the container width */
  max-height: 70vh;
  /* Ensure the image does not exceed the viewport height */
  object-fit: contain;
  /* Maintain the aspect ratio and fit the image into the container */
  opacity: 0;
  /* Start with 0 opacity */
  transition: opacity 3s ease-in-out;
}

.billboardContainer img.loaded {
  opacity: 1;
  /* Set opacity to 1 when the image is loaded */
}

.billboard {
  position: relative;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  margin: auto;
}

.billboardLogo {
  opacity: 0.2;
  position: absolute;
  top: 50%;
  left: 50%;
}



.navbarList {
  margin: auto;
}

.navbarLink {
  color: #fff;
  font-size: 16px;
  padding: 60px;
  position: relative;
  text-decoration: none !important;
  text-decoration-color: none !important;
}

.navbarContainer {
  height: 80px;
  background-color: #181818;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1000000;
}
.navbarLink:after {
	position: absolute;
	top: 60%;
	left: 0;
	width: 100%;
	height: 1px;
	background: #fff;
	content: '';
	opacity: 0;
	-webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
	-moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
	transition: height 0.3s, opacity 0.3s, transform 0.3s;
	-webkit-transform: translateY(-10px);
	-moz-transform: translateY(-10px);
	transform: translateY(-10px);
}

.navbarLink:hover:after, .navbarLink:focus:after {
	height: 3px;
	opacity: 1;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	transform: translateY(0px); 
}

.newsContainer {
  background-color: #2c1e53;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.newsContainer h1 {
  /* opacity: 0.2; */
  color: #fff;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 50px;
  text-align: center;
  line-height: 35px;
  font-weight: bold;
  margin: 0.67em 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainNews {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 400px; */
  /* padding: 20px; */
}

.mainNews p {
  color: #969696;
  font-size: 18px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.mainNews h3 {
  color: #ffffff;
}

.newsList {
  list-style: none;
}

.newsArticle1 {
  background-color: #2c1e53;
  margin: 20px;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
}

.newsArticle2 {
  background-color: #2c1e53;
  margin: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.newsArticle3 {
  background-color: #2c1e53;
  margin: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.newsImage {
  max-width: 20%;
  height: auto;
}

.galleryContainer {
  align-items: center;
  top: 0px;
  background-color: #2c1e53;
}

.membersContainer {
  align-items: center;
  top: 0px;
  background-color: #ffffff;
}

.aboutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
  max-width: 940px;
}

.aboutContainer, .newsContainer {
  padding: 80px 40px;
}

.aboutContainer h1 {
  opacity: 0.2;
  font-size: 50px;
  text-align: center;
  line-height: 35px;
  margin-top: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  margin: 0.67em 0;
}

.aboutContainer h3 {
  opacity: 0.2;
  font-size: 30px;
  text-align: center;
  line-height: 35px;
  margin-top: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  margin: 0.67em 0;
}

.aboutArticle {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: justify;
  color: #333;
}

.aboutImage {
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  max-width: 100%;
  width: 125px;
  overflow-clip-margin: content-box;
  overflow: clip;
  margin-bottom: 30px;
}

.footerContainer {
  background-color: #000;
  display: block;
  color: #fff;
  padding-top: 50px;
  padding-bottom: 50px;
}

.footerInnerContainer {
  list-style: none;
}

.footerImgContainer {
  padding-top: 20px;
  text-align: center;
}

.footerText {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  text-align: center;
}

.contactContainer {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #181818;
  padding-top: 50px;
  padding-bottom: 50px;
}

.contactInnerContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
  text-align: center;
}

.contactTitle {
  background-color: transparent;
  opacity: 0.4;
  color: #fff;
  font-size: 50px;
}

.contactBlock {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  display: block;
}

.contactBlockEmail {
  font-size: 18px;
  line-height: 30px;
  color: #fff;
}

.specificContactContainer {
  /* height: 100%; */
  margin-top: 45px;
  box-sizing: border-box;
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #333;
}

.contactBlockName {
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  font-weight: bold;
}

.sponsorsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.sponsorsContainer h1 {
  opacity: 0.2;
  font-size: 50px;
  text-align: center;
  line-height: 44px;
  margin-top: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  margin: 0.67em 0;
}

.sponsorInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sponsorInnerContainer h1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sponsorImage {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  max-width: 100%;
  width: 150px;
  overflow-clip-margin: content-box;
  overflow: clip;
  margin-bottom: 30px;
  object-fit: contain;
}

#about:target {
  padding-top: 80px;
}

#sponsors:target {
  padding-top: 80px;
}

/**
  OVERWRITTEN BOOTSTRAP CLASSES
**/
.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

a {
  color: white !important;
}

/**
  MEDIA QUERY FOR MOBILE DEVICES
**/
.hambMenu {
  display: none; /* Initially hide the icon on larger screens */
  cursor: pointer;
}

.hambMenuBar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}

@media only screen and (max-width: 767px) {
  .navbarList {
    display: none; /* Hide the regular links on smaller screens */
    text-align: center;
  }

  /* .hambMenu {
    display: block;
    position: fixed;
    top: 20px;
    right: 20px;
    border: #000 solid;
    border-radius: 100%;
    background: #000;
  } */

  .navbarLink {
    padding: 15px;
  }

  .navbarContainer {
    height: auto;
  }

  /* Show the links when the menu is open */
  .navbarList.show {
    display: block;
  }

  .newsContainer h1 {
    font-size: 36px; /* Adjust the font size for smaller screens */
    line-height: 30px; /* Adjust the line height for smaller screens */
    margin-top: 10px; /* Adjust the top margin for smaller screens */
    margin-bottom: 5px; /* Adjust the bottom margin for smaller screens */
    /* Additional flexbox styles for smaller screens if needed */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .billboardContainer {
    height: 100vh;
  }

  .billboardContainer img {
    max-width: 80%; /* Adjust the max-width for smaller screens */
    max-height: 50vh; /* Adjust the max-height for smaller screens */
  }

  .aboutContainer h1 {
    opacity: 0.2;
    font-size: 45px;
    text-align: center;
    line-height: 30px;
    margin-top: 10px;
    font-weight: bold;
    margin-bottom: 5px;
    margin: 0.67em 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aboutContainer h3 {
    opacity: 0.2;
    font-size: 30px;
    text-align: center;
    line-height: 30px;
    margin-top: 10px;
    font-weight: bold;
    margin-bottom: 5px;
    margin: 0.67em 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aboutEntities ul {
    text-align: center;
    list-style: none;
    margin-top: 10px;
    /* font-weight: bold; */
    /* margin-top: 50px; */
  }

  .aboutEntities li {
    text-align: center;
    list-style: none;

  }

  .sponsorInnerContainer {
    flex-direction: column;
  }

  .sponsorsContainer h1 {
    opacity: 0.2;
    font-size: 36px;
    text-align: center;
    line-height: 30px;
    margin-top: 10px;
    font-weight: bold;
    margin-bottom: 15px;
    margin: 1.67em 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sponsorInnerContainer img {
    max-width: 40%; /* Adjust the max-width for smaller screens */
    max-height: 30vh; /* Adjust the max-height for smaller screens */
  }
  
  .aboutArticle {
    margin-left: 15px;
    margin-right: 15px;
  }

  .aboutImage {
    max-width: 40%; /* Adjust the max-width for smaller screens */
    max-height: 30vh; /* Adjust the max-height for smaller screens */
  }

  .contactTitle {
    opacity: 0.2;
    font-size: 36px;
    text-align: center;
    line-height: 30px;
    margin-top: 10px;
    font-weight: bold;
    margin-bottom: 5px;
    margin: 0.67em 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contactContainer {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .newsImage {
    max-width: 60%;
    height: auto;
  }
}
